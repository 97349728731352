<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="theData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Start -->
          <validation-provider
            #default="validationContext"
            name="reference_id"
          >
            <label>Reference Code</label>
            <b-form-input
              id="abbr"
              v-model="theData.reference_id"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Any reference code unique to the pharmacy"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="address"
            rules="required"
          >
            <label>Address</label>
            <b-form-textarea
              id="textarea-default"
              v-model="theData.address"
              placeholder="Pharmacy Address"
              rows="3"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="pharmacist"
            rules=""
          >
            <label class="warning">Assign Pharmacists</label>
            <v-select
              v-model="theData.pharmacist"
              multiple
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pharmacistList"
              :reduce="val => val.value"
              :clearable="false"
              input-id="pharmacists"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="cde_hcps"
            rules=""
          >
            <label class="warning">Assign CDE/HCP</label>
            <v-select
              v-model="theData.cde_hcps"
              multiple
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="cdeHcpList"
              :reduce="val => val.value"
              :clearable="false"
              input-id="cdeHCP"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="patients"
            rules=""
          >
            <label class="warning">Assign Patients</label>
            <v-select
              v-model="theData.patients"
              multiple
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="patientList"
              :reduce="val => val.value"
              :clearable="false"
              input-id="patients"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      pharmacistList: [],
      cdeHcpList: [],
      patientList: [],
    }
  },
  created() {
    this.fetchPharmacists()
    this.fetchCdeHcps()
    this.fetchPatients()
  },
  methods: {
    refetchData() {
      return this.$forceUpdate()
    },

    fetchPharmacists() {
      const toast = useToast()
      store
        .dispatch('userStore/fetchPharmacistLabels')
        .then(response => {
          this.pharmacistList = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Pharmacist list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    fetchCdeHcps() {
      const toast = useToast()
      store
        .dispatch('userStore/fetchCdeHcpLabels')
        .then(response => {
          this.cdeHcpList = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching CDE HCP list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    fetchPatients() {
      const toast = useToast()
      store
        .dispatch('userStore/fetchPatientLabels')
        .then(response => {
          this.patientList = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching patient list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },

  setup(props, { emit }) {
    const blankData = {
      name: '',
      abbr: '',
    }

    const theData = ref(JSON.parse(JSON.stringify(blankData)))

    const resetData = () => {
      theData.value = JSON.parse(JSON.stringify(blankData))
    }

    const onSubmit = () => {
      theData.value.active = true
      store.dispatch('pharmacyStore/addPharmacy', theData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-sidebar-active', false)
          this.$bvToast.toast('New Record Created', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        }).catch(() => {
          this.$bvToast.toast('Sorry we could not create new record. please try again.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      theData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
